.contact-banner {
   display: flex;
   flex-direction: column;
   margin: 20vh 10vw 40vh 10vw;
}
.contact-banner p:nth-child(1) {
   font-weight: bold;
   font-size: 3em;
   margin-bottom: 25px;
}
.email-button {
   color: white;
   font-weight: bold;
   width: fit-content;
   border-radius: 9px;
   padding: 10px 18px;
   box-shadow: 5px 5px 8px rgb(143, 143, 143);
   margin-top: 15px;
   font-size: 0.8em;
   background-color: #154042;
}
