.content .title {
  font-family: "PoppinsMedium";
}
.content .name {
  width: fit-content;
  background-color: rgb(17 32 30 / 33%);
  background-image: linear-gradient(45deg, #c7c5c5b7, var(--green));
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

/*COMPANIES BANNER*/
.content .company-banner {
  background: linear-gradient(
    315deg,
    #215457 3%,
    rgba(60, 132, 206, 1) 38%,
    rgba(48, 238, 226, 1) 68%,
    #246b6e 98%
  );
  animation: gradient 20s ease infinite;
  -webkit-animation: gradient 20s ease infinite; /* Safari 4+ */
  -moz-animation: gradient 20s ease infinite; /* Fx 5+ */
  -o-animation: gradient 20s ease infinite; /* Opera 12+ */
  background-size: 400% 400%;
  background-attachment: fixed;
}
.content .company-banner .img-wrapper .tabsystems-img {
  filter: brightness(0.5) contrast(15) hue-rotate(210deg);
}
.content .company-banner .img-wrapper .iplus-img {
  filter: brightness(0.7) contrast(8) hue-rotate(110deg) saturate(5);
}

/*KEYFRAMES*/
@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}
