.pagenotfound {
   height: calc(100vh - (73px + 9vh + 16px));
   justify-content: center;
}
.content-pnf {
   justify-content: center;
   display: flex;
   flex-direction: column;
   height: 100%;
}
.pagenotfound p {
   text-align: center;
   font-weight: bold;
   font-size: 1.2em;
}