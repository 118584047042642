
@media only screen and (max-width: 680px) {
  .projects .project-div {
    flex-direction: column;
  }
  .projects .project-div .description {
    padding-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }
  .projects .project-div .description .desc-section {
    max-width: 50vw;
    margin-left: 0;
    margin-right: 0;
  }
  .projects .project-div .proj-img .mobile-img {
    width: 230px;
    height: 470px;
  }
}

@media only screen and (min-width: 680px) {
  .projects .project-div .description .desc-section {
    max-width: 30vw;
    margin-left: 55px;
    margin-right: 55px;
  }
  .projects .project-div .proj-img .mobile-img {
    width: 290px;
    height: 550px;
  }
}

.projects .project-div .description {
  margin-top: auto;
  margin-bottom: auto;
  flex: 1;
}
.projects .project-div .description .desc-section {
  text-align: justify;
}
.projects .project-div .proj-img {
  flex: 1;
  text-align: center;
}
.projects .project-div .proj-img .mobile-img {
  margin-top: 70px;
  border-radius: 25px;
  border: 7px solid black;
  box-shadow: 7px 7px 4px #ccc;
  -moz-box-shadow: 7px 7px 4px #ccc;
  -webkit-box-shadow: 7px 7px 4px #ccc;
  -khtml-box-shadow: 7px 7px 4px #ccc;
}
.projects .project-div .proj-img .desktop-img {
  margin-top: 190px;
  border-radius: 25px;
  border: 7px solid black;
}
