/*PLUS & MINUS*/
.closed .vertical {
  transition: all 0.5s ease-in-out;
  transform: rotate(-90deg);
}
.closed .horizontal {
  transition: all 0.5s ease-in-out;
  transform: rotate(-90deg);
  opacity: 1;
}
.opened {
  opacity: 1;
}
.opened .vertical {
  transition: all 0.5s ease-in-out;
  transform: rotate(90deg);
}
.opened .horizontal {
  transition: all 0.5s ease-in-out;
  transform: rotate(90deg);
  opacity: 0;
}
.circle:hover {
  cursor: pointer;
}
.circle-plus {
  height: 4em;
  width: 4em;
  font-size: 1em;
  opacity: 0.7;
}
.circle-plus .circle {
  position: relative;
  width: 2.55em;
  height: 2.5em;
}
.circle-plus .circle .horizontal {
  position: absolute;
  background-color: var(--green);
  width: 30px;
  height: 5px;
  left: 50%;
  margin-left: -15px;
  top: 50%;
  margin-top: -2.5px;
}
.circle-plus .circle .vertical {
  position: absolute;
  background-color: var(--green);
  width: 5px;
  height: 30px;
  left: 50%;
  margin-left: -2.5px;
  top: 50%;
  margin-top: -15px;
}

/*OTHER*/
.faq {
  background-color: #d8e2d8;
  margin: 30vh 0 25vh 0;
  padding: 10vh 10vw 10vh 10vw;
}
.question-list {
  margin: 5vh 0 5vh 0;
}
.answer {
  margin-top: 35px;
  margin-left: 40px;
}
.question {
  display: flex;
  align-items: center;
}
hr {
  width: 60vw;
  margin-left: 0;
}
