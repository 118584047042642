.section-hero {
   padding-top: 15vh;
   padding-bottom: 17vh;
   padding-left: 15vw;
   padding-right: 15vw;
   margin-bottom: 10vh;
}
.section-hero .box {
   background-color: var(--orange);
   border-radius: 25px;
}
.section-hero .box .hero-content {
   text-align: center;
}
/* .section-hero .box .hero-content p:nth-child(1) {
   font-size: 2.5em;
} */

@media only screen and (min-width: 680px) {
   .section-hero .box {
      padding-top: 13vh;
      padding-bottom: 13vh;
      padding-left: 5vw;
      padding-right: 5vw;
   }
}

@media only screen and (max-width: 680px) {
   .section-hero .box {
      padding: 30px;
   }
   /* .section-hero .box .hero-content p:nth-child(1) {
      font-size: 2em;
   } */
}

