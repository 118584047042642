.button-work {
   display: inline-flex;
   width: 210px;
   height: 55px;
   padding: 18px;
   border-radius: 25px;
   background-image: linear-gradient(90deg, rgb(17 32 30 / 33%), var(--green));
}
.button-work img {
   width: 14px;
   height: auto;
   margin: 0 0 0 10px;
   background-color: transparent;
   filter: invert();
}
.button-work button {
   color: white;
   background-color: transparent;
   border: none;
   font-family: 'Poppins';
   font-size: 0.9rem;
}