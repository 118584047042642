.skills {
  display: flex;
  flex-direction: column;
  margin: 30vh 10vw 30vh 10vw;
  align-items: center;
}
.switch {
  display: grid;
  width: 330px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  border-radius: 30px;
  padding: 3px;
  background-color: var(--light-orange);
  margin: 1vh 0 10vh 0;
}
.slider {
  background-color: var(--orange);
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  z-index: 1;
  border-radius: 30px 0 0 30px;
}
.switch p {
  z-index: 2;
  margin: 0;
  padding: 16px;
  grid-column-start: 1;
  grid-row-start: 1;
  text-align: center;
  font-weight: bold;
}
.switch p:nth-child(2) {
  grid-column-start: 2;
  grid-row-start: 1;
  text-align: center;
  font-weight: bold;
}
.skills-swiper {
  display: inline-flex;
  height: 15vh;
  width: 100%;
  justify-content: space-between;
}
.skill {
  text-align: center;
  width: 100%;
  padding: 15px 5px 0 5px;
  border-radius: 25px;
  margin: 0 5px 0 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: linear-gradient(135deg, rgba(227, 174, 153, 0.25), var(--orange));
}
.skill p {
  font-weight: bold;
  margin: 0 5px 2px 5px;
}
.skill img {
  margin-right: auto;
  margin-left: auto;
  height: 55px;
}
.web-icons {
  display: flex;
  height: 40%;
  justify-content: center;
}
.web-icons img {
  height: 100%;
}
#js-img {
  width: 50%;
}
.switch:hover {
  cursor: pointer;
}
@media only screen and (max-width: 901px) {
  .skills-swiper {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    height: inherit;
    width: inherit;
    justify-content: unset;
    gap: 5px;
  }
  .web-icons {
    display: block;
    height: unset;
  }
  .skill .web-icons img {
    height: 70% !important;
  }
  .skill {
    justify-content: space-around;
    padding: unset;
    margin: unset;
  }
  .skill img {
    width: 30%;
    height: 40%;
    margin-top: 15px;
  }
}
@media only screen and (max-width: 620px) {
  .skills-swiper {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    height: inherit;
    width: inherit;
    justify-content: unset;
    gap: 5px;
  }
  .web-icons {
    display: block;
    height: unset;
  }
  .web-icons img {
    height: 70%;
  }
  .skill {
    justify-content: space-around;
    padding: unset;
    margin: unset;
  }
  .skill img {
    width: 30%;
    margin-top: 15px;
  }
  .skill {
    padding-bottom: 10px;
  }
  .skill p {
    margin-top: 15px;
  }
}
