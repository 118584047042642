.social-icon a img {
  height: 22px;
  width: 22px;
  filter: invert(100%);
}
.social-icon a:hover {
  background-color: white;
  transform: scale(110%);
  transition: 0.3s;
}
.social-icon a:hover img {
  transform: scale(105%);
  transition: 0.3s;
}
.social-icon a {
  margin: 10px 10px;
  padding: 10px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.logo a {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 60px;
  color: var(--black);
  font-family: "PoppinsMedium";
}
.logo .logo-img img {
  width: 70px;
  filter: invert(100%);
}

@media only screen and (max-width: 680px) {
  nav {
    display: block;
  }
  .logo a {
    display: none;
  }
  .logo .logo-img {
    margin-left: 25px;
    display: block;
  }
  .social-icon {
    display: none;
  }
}

@media only screen and (min-width: 680px) {
  nav {
    display: none;
  }
  .logo a {
    display: block;
  }
  .logo .logo-img {
    margin-left: 90px;
  }
  .social-icon {
    display: flex;
  }
}

/*HAMBURGER MENU*/
#menuToggle {
  z-index: 1;
  margin-right: 15px;
  -webkit-user-select: none;
  user-select: none;
  margin-top: -35px;
}
#menuToggle a {
  text-decoration: none;
  color: var(--green);
  transition: color 0.3s ease;
}
#menuToggle a img {
  filter: invert(100%);
}
#menuToggle a:hover {
  color: var(--orange);
}
#menuToggle input {
  display: block;
  width: 40px;
  height: 35px;
  position: relative;
  top: 21px;
  right: 8px;
  cursor: pointer;
  opacity: 0;
  z-index: 3;
  -webkit-touch-callout: none;
}
#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: var(--green);
  border-radius: 3px;

  z-index: 2;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: var(--green);
}

#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}
#menu {
  position: absolute;
  width: 100vw;
  right: 0;
  margin: -460px 0 0 -350px;
  padding: 50px;
  z-index: 1;
  padding-top: 125px;
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: none;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menu li {
  padding: 10px 0;
  font-size: 22px;
}

#menuToggle input:checked ~ ul {
  transform: translate(0, 100%);
}
