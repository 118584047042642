.form-text {
  text-align: left;
  color: var(--black);
}
.form-text p {
  margin: 25px 0 25px 0;
}
.form-text p:nth-child(1) {
  font-size: 1.8em;
  font-weight: bold;
}
.form-text p:nth-child(4) {
  color: #154042;
  font-size: 1.2em;
  font-weight: bold;
}
.contact {
  margin: 0 10vw 10vh 10vw;
}
.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}
.contact .box {
  text-align: center;
}
.contact form .hidden {
  display: none;
}
.contact form input,
.contact form textarea {
  width: 100%;
  background: var(--light-orange);
  border: 1px solid var(--light-orange);
  border-radius: 9px;
  color: var(--green);
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: var(--light-orange);
}
.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: white;
  background-color: #154042;
  border: 1px solid #154042;
  border-radius: 25px !important;
  padding: 10px 40px;
  width: 100%;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  font-size: 0.9em;
}
@media only screen and (max-width: 767px) {
  .form-text {
    margin-bottom: 15vh;
  }
}
.labels {
  text-align: left;
  font-weight: bold;
}
