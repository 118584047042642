.footer-div {
   border-top: 1px var(--blue) solid;
   padding-top: 10px;
   padding-bottom: 10px;
   display: flex;
   align-items: center;
   justify-content: space-between;
}
.footer-div p {
   font-weight: bold;
   font-size: 0.9em;
   margin-left: 15px;
}
.social-icons-footer {
   margin-right: 15px;
   display: flex;
 }
 .social-icons-footer a img {
   height: 22px;
   width: 22px;
   filter: invert(100%);
 }
.social-icons-footer a:hover {
   background-color: white;
   transform: scale(110%);
   transition: 0.3s;
 }
.social-icons-footer a:hover img {
   transform: scale(105%);
   transition: 0.3s;
 }
.social-icons-footer a {
   margin: 10px 10px;
   padding: 10px;
   border-radius: 50%;
   display: inline-flex;
   align-items: center;
   justify-content: center;
 }