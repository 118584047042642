@tailwind base;
@tailwind components;
@tailwind utilities;

/*CUSTOM FONTS*/
@font-face {
  font-family: "PoppinsBold";
  src: url("./assets/fonts/Poppins-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "PoppinsMedium";
  src: url("./assets/fonts/Poppins-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Light.ttf");
  font-weight: 400;
}

body {
  background-color: white;
  margin: 0;
  overflow-x: hidden !important;
  font-family: "Poppins" !important;
  scroll-behavior: smooth;
}
html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
body,
.project-div {
  scroll-behavior: smooth;
}
a {
  text-decoration: none !important;
}
section {
  position: relative;
}
