
.quality-box > p {
   margin-bottom: 10vh;
   font-size: 1.5em;
   width: 350px;
}
.list-of-qualities {
   display: inline-flex;
}
.qualities-div{
   width: 33.3%;
}
.qualities-div:nth-child(2) {
   margin: 0 25px 0 25px;
}
.quality-title {
   font-weight: bold;
}
.qualities-div img {
   width: 45px;
   height: 45px;
   filter: invert(74%) sepia(36%) saturate(327%) hue-rotate(330deg) brightness(96%) contrast(85%);
}
@media only screen and (max-width: 558px) {
   .list-of-qualities {
      flex-direction: column;
      align-items: center;
   }
   .qualities-div{
      width: 100%;
   }
   .qualities-div:nth-child(2) {
      margin: 7vh 0 7vh 0;
   }
}
@media only screen and (max-width: 440px) {
   .quality-box > p {
      width: 65vw;
   }
}